<template>
    <div>
        <custom-button :class="additionalButtonClasses" :colourType="buttonType" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </custom-button>
        <portal to="destination">
            <large-model v-show="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
            :confirmationString='`Are you sure you want to \"${action.title}\"`'
            @removeFromDisplay="displayModal = false" @perform="perform()">

                <!-- Templates -->
                <property-or-field @input="change" :action="action"
                                   fieldId="qa_category_ids"
                                   v-model="formData['qa_category_ids']"
                ></property-or-field>
                <div v-show="errors.has('qa_category_ids')" class="text-red-600">{{ errors.first('qa_category_ids') }}</div>

                    <div>
                        <input id="chkUseImport" class="mr-2" type="checkbox" value="false" @input="setUsingImport"/>
                        <label for="chkUseImport">Set {{setIdentifier1Title(type, 'using file')}}</label>

                        <property-or-field class="mt-2" v-if="!usingImport" :action="action"
                                           fieldId="plots" propertyKey="plots"
                                         v-model="formData['plots']"></property-or-field>
                        <div v-show="errors.has('plots') && !usingImport" class="text-red-600">{{ getIdentifiersErrors(type, errors.first('plots')) }}</div>

                        <p v-if="usingImport" class="text-sm leading-5 text-gray-500 my-2">Please upload the {{ setIdentifier1Title(type, '') }} in the correct format. <a @click="downloadTemplate(type)" class="underline cursor-pointer hover:text-gray-900">Click here to download the template.</a></p>

                        <property-or-field v-if="usingImport" class="mt-2" :action="action"
                                           fieldId="plots_via_csv" propertyKey="plots_via_csv"
                                           v-model="formData['plots_via_csv']"></property-or-field>
                        <div v-show="errors.has('plots_via_csv') && usingImport" class="text-red-600">{{ getIdentifiersErrors(type,errors.first('plots_via_csv')) }}</div>
                    </div>


            </large-model>
        </portal>
    </div>
</template>

<script>
    import CustomButton from "@/v3/Buttons/CustomButton.vue";
    import SlideOver from "@/v2/components/SlideOver";
    import fileDownload from "js-file-download";
    import PropertyOrField from "@/components/PropertyOrField";
    import DependantField from "@/components/DependantField";
    import LargeModel from "@/v3/models/LargeModel.vue";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                clientId: null,
                projectId: null,
                type: null,
                usingImport: false
            }
        },
        components: {
            LargeModel,
            SlideOver,
            CustomButton,
            PropertyOrField,
            DependantField
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            },
            buttonType:{
                type: String,
                default: 'primary'
            },
            additionalButtonClasses: {
                type: String,
                default: null
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                var that = this;
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined){
                        payload[field.name] = field.value
                        if(field.name === 'type'){
                            that.setType(field.value)
                        }
                    };
                });
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                console.log(field);
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            setType(value) {
                this.type = value;
            },
            setIdentifier1Title(type, currentTitle){
                return currentTitle = currentTitle.replace("Identifiers ", "");
            },
            setIdentifier2Title(type){
                if (type === 'communal')
                {
                    return 'Area';
                }
                return 'Unit';
            },
            setUsingImport(element){
                this.usingImport = element.srcElement.checked;
            },
            getIdentifiersErrors(type, error){
                if (error) {
                    if (type === 'communal') {
                        return error.replace('plots', 'levels and areas');
                    }
                    return error.replace('plots', 'levels and units');
                }
                return error;
            },
            downloadTemplate(type){
                fileDownload('"ID 1","ID 2","ID 3"\n ' +
                    'ID 1-1, ID 2-1, ID 3-1\n ' +
                    'ID 1-1, ID 2-2, ID 3-1\n ' +
                    'ID 1-2, ID 2-2, ID 3-2\n ' +
                    'ID 1-2, ID 2-3, ID 3-1','qa-tracker-csv-template.csv');
            }
        }


    }
</script>

<style lang="scss">

</style>
